import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { State } from '@/types';
import { Dashboard, PropertyRequests, NewLogIn } from '@/components';

export const PropertyRequestsPage = () => {
  const { isLoggedIn } = useSelector(({ auth }: State) => auth);
  const { pathname } = useLocation();

  return (
    <Dashboard>
      {isLoggedIn ? <PropertyRequests /> : <NewLogIn route={pathname} />}
    </Dashboard>
  );
};
