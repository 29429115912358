import { useState, MouseEvent, useEffect, CSSProperties } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Link,
  IconButton,
  AppBar,
  Toolbar,
  Box,
  Container,
  useMediaQuery,
  Typography,
} from '@mui/material';

import { State, HeaderDialogsEnum, UserKindEnum, UserTariffPlanEnum, NotificationTypeEnum } from '@/types';
import { stringAvatar } from '@/utils';
import { LogIn, SignUp, DialogTariffPlan } from '@/components';

import {
  StyledLogo,
  StyledButtonsAvatar,
  StyledButton,
  StyledButtonSecondary,
  StyledIconButton,
  StyledButtonOutlined,
} from './stylesHeader';
import AddAd from './addAd.svg';
import Dollar from './dollar.svg';
import AddRequest from './addRequest.svg';
import AddTelegram from './telegram.svg';
import { useDialogs } from '@/components/Dashboard/DialogsContext';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccountMenu } from '@/components/Sidebar/AccountMenu';
import { HeaderMenu } from '@/components/Sidebar/HeaderMenu';
import { usersProvider } from '@/providers';
import { appSetNotification } from '@/store';

export const Header = () => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const { openDialog, setOpenDialog } = useDialogs();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const { isLoggedIn } = useSelector(({ auth }: State) => auth);
  const user = useSelector(({ auth }: State) => auth.user);
  const isAbove900 = useMediaQuery('(min-width:1200px)');
  const isAccountMenuOpen = Boolean(anchorEl);

  const isDisabledCreatingAd =
    user.kind === UserKindEnum.Foreign ||
    (user.kind === UserKindEnum.Broker && user.employer === null) ||
    (user.kind === UserKindEnum.Manager && user.employer === null);

  const isForeign = user.kind === UserKindEnum.Foreign;
  const isManager = user.kind === UserKindEnum.Manager;
  const isBroker = user.kind === UserKindEnum.Broker;
  const isAgency = user.kind === UserKindEnum.Agency;
  const isDeveloper = user.kind === UserKindEnum.Developer;
  const isForeignPermission = isForeign && user.verified;
  const isManagerPermission = isManager && user.employer !== null;
  const isBrokerPermission = isBroker && user.employer !== null;
  const isClientRequestVisible =
    isBrokerPermission || isManagerPermission || isForeignPermission || isDeveloper || isAgency;

  const isDisabledCreatingRequest =
    user.kind === UserKindEnum.Foreign ||
    (user.kind === UserKindEnum.Broker && user.employer === null) ||
    (user.kind === UserKindEnum.Manager && user.employer === null);

  const isTariffFree = user.tariffPlan?.name === UserTariffPlanEnum.Free;
  const isTariffTrial = user.tariffPlan?.name === UserTariffPlanEnum.Trial;
  const isTariffVisible = isTariffFree || isTariffTrial;

  const isVisibleUpgrade =
    (user.kind === UserKindEnum.Broker && user.employer === null) ||
    (user.kind === UserKindEnum.Manager && user.employer === null);

  const isAdmin = user.kind === UserKindEnum.Admin;
  const isVisible = !user || isDisabledCreatingAd;

  useEffect(() => {
    if (isAbove900) {
      setIsSidebarOpen(false);
    }
  }, [isAbove900]);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);

  const handleClickOpenDialog = (dialogType: HeaderDialogsEnum) => {
    setOpenDialog((prevState) => ({
      ...prevState,
      [dialogType]: !prevState[dialogType],
    }));
  };

  const hasSubscriptions = user.hasSubscriptions || false;
  const isTrial = user.statusSubscription === 'trialing';

  const calculateDaysRemaining = (trialEndDate: string): number => {
    const currentDate = new Date();
    const trialEnd = new Date(trialEndDate);
    const timeDifference = trialEnd.getTime() - currentDate.getTime();
    return Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  };
  const trialPeriod = user?.trialEnd && calculateDaysRemaining(user?.trialEnd);

  const periodValue = isTariffFree 
    ? hasSubscriptions 
      ? 'Upgrade'
      : 'Get trial' 
    : 
      isTrial && trialPeriod
      ? `Trial: ${trialPeriod} days left`
      : 'Active';


  if (isLoggedIn === null) return null;
  return (
    <header
      data-qa='header'
      style={{
        height: '72px',
        boxShadow: '0px 1px 1px 0px #00000026',
        fontSize: '16px',
        zIndex: '1200',
      }}
    >
      <AppBar
        component='nav'
        position='fixed'
        color='default'
        elevation={0}
        sx={{
          borderRadius: 0,
          backgroundColor: '#FFF',
        }}
      >
        <Container style={{ maxWidth: '1300px', padding: '0 16px 0 32px' }}>
          <Toolbar style={{ display: 'flex', padding: '0px' }}>
            <Link href='/'>
              <StyledLogo src={'/Img/logo.svg'} />
            </Link>
            <Box sx={{ marginLeft: 'auto' }}>
              <StyledButtonsAvatar>
                {isLoggedIn && (
                  <>
                    {isAbove900 && (
                      <>
                        <UpgradeButton
                          style={{ maxWidth: '173px', width: 'auto' }}
                          isVisible={!user || isVisibleUpgrade || !isTariffVisible}
                          isAdmin={isAdmin}
                          handleClickOpenDialog={handleClickOpenDialog}
                          hasSubscriptions={hasSubscriptions}
                        />
                        <TelegramBotButton
                          style={{ maxWidth: '220px', width: 'auto' }}
                          isTariffFree={isTariffFree}
                          isClientRequestVisible={isClientRequestVisible}
                          handleClickOpenDialog={handleClickOpenDialog}
                        />
                        <AddListingButton
                          style={{ maxWidth: '173px', width: 'auto' }}
                          isVisible={isVisible}
                          isAdmin={isAdmin}
                          handleClickOpenDialog={handleClickOpenDialog}
                        />
                        <ClientRequestButton
                          style={{ maxWidth: '220px', width: 'auto' }}
                          isVisible={isVisible}
                          isAdmin={isAdmin}
                          isTariffFree={isTariffFree}
                          isClientRequestVisible={isClientRequestVisible}
                          handleClickOpenDialog={handleClickOpenDialog}
                        />
                        <Box
                          sx={{
                            display: 'flex',
                            gap: '8px',
                            cursor: 'pointer',
                            alignItems: 'center',
                          }}
                          onClick={handlePopoverOpen}
                        >
                          <StyledIconButton
                            size='small'
                            aria-controls={isAccountMenuOpen ? 'account-menu' : undefined}
                            aria-haspopup='true'
                            aria-expanded={isAccountMenuOpen ? 'true' : undefined}
                          >
                            <Avatar src={user?.media?.[0]?.fileUrl} {...stringAvatar(user?.name)} />
                            <Box sx={{ marginLeft: '5px', color: '#2a3842' }}>
                              <Typography
                                sx={{ fontSize: '14px', fontWeight: 600, textAlign: 'left' }}
                              >
                                {user.tariffPlan?.name.split(' ')[0]}
                              </Typography>
                              <Typography
                                sx={{ fontSize: '14px', textAlign: 'left', opacity: 0.5 }}
                              >
                                {periodValue}
                              </Typography>
                            </Box>
                          </StyledIconButton>
                          <ExpandMoreIcon
                            sx={{
                              transform: isAccountMenuOpen ? 'rotate(180deg)' : 'none',
                            }}
                          />
                        </Box>
                        <AccountMenu
                          anchorEl={anchorEl}
                          open={isAccountMenuOpen}
                          handleClose={() => setAnchorEl(null)}
                        />
                      </>
                    )}

                    {!isAbove900 && (
                      <IconButton
                        edge='start'
                        color='inherit'
                        aria-label='open drawer'
                        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                      >
                        {isSidebarOpen ? <CloseIcon /> : <MenuIcon />}
                      </IconButton>
                    )}
                  </>
                )}
              </StyledButtonsAvatar>
              <SignUp handleOpenDialog={handleClickOpenDialog} openDialog={openDialog} />
              <LogIn handleOpenDialog={handleClickOpenDialog} openDialog={openDialog} />
            </Box>
          </Toolbar>
          {isSidebarOpen && (
            <HeaderMenu
              isVisible={isVisible}
              isUpgradeButtonVisible={!user || isVisibleUpgrade || !isTariffVisible}
              isAdmin={isAdmin}
              isClientRequestVisible={isClientRequestVisible}
              isTariffFree={isTariffFree}
              onClick={() => {
                setIsSidebarOpen(false);
              }}
            />
          )}
        </Container>
      </AppBar>
      <DialogTariffPlan
        open={openDialog.tariff}
        onClose={() => handleClickOpenDialog(HeaderDialogsEnum.Tariff)}
      />
    </header>
  );
};

export function UpgradeButton({
  style,
  isVisible,
  isAdmin,
  handleClickOpenDialog,
  hasSubscriptions,
}: {
  style?: CSSProperties;
  isVisible?: boolean;
  isAdmin?: boolean;
  hasSubscriptions: boolean;
  handleClickOpenDialog: (value: HeaderDialogsEnum) => void;
}) {
  if (isVisible) return null;

  return (
    <StyledButton
      style={{ width: '100%', ...style }}
      type='submit'
      variant='contained'
      color='primary'
      onClick={() => handleClickOpenDialog(HeaderDialogsEnum.Tariff)}
    >
      <img src={Dollar} alt={`${hasSubscriptions} ? 'Upgrade' : 'Get trial'`} />
      {hasSubscriptions ? 'Upgrade' : 'Get trial'}
    </StyledButton>
  );
}

export function AddListingButton({
  style,
  isVisible,
  isAdmin,
  handleClickOpenDialog,
}: {
  style?: CSSProperties;
  isVisible?: boolean;
  isAdmin?: boolean;
  handleClickOpenDialog: (value: HeaderDialogsEnum) => void;
}) {
  const navigate = useNavigate();

  if (isVisible) return null;

  return (
    <StyledButton
      style={{ width: '100%', ...style }}
      type='submit'
      variant='contained'
      color='primary'
      onClick={() => {
        if (isAdmin) {
          navigate('/admin/offplan/create');
        } else {
          navigate('/ads/create');
        }
      }}
    >
      <img src={AddAd} alt='Add Ad' />
      {isAdmin ? 'Add Off Plan' : 'Add listing'}
    </StyledButton>
  );
}

export function ClientRequestButton({
  style,
  isVisible,
  isAdmin,
  isClientRequestVisible,
  handleClickOpenDialog,
  isTariffFree,
}: {
  style?: CSSProperties;
  isVisible?: boolean;
  isAdmin?: boolean;
  isTariffFree?: boolean;
  isClientRequestVisible?: boolean;
  handleClickOpenDialog: (value: HeaderDialogsEnum) => void;
}) {
  const navigate = useNavigate();

  // if (isVisible || isAdmin) return null;
  if (!isClientRequestVisible) return null;

  return (
    <StyledButtonOutlined
      style={{ width: '100%', ...style }}
      type='submit'
      variant='outlined'
      onClick={() =>
        isTariffFree
          ? handleClickOpenDialog(HeaderDialogsEnum.Tariff)
          : navigate('/client-request/create')
      }
    >
      <img src={AddRequest} alt='Add client request' />
      Add client request
    </StyledButtonOutlined>
  );
}

export function TelegramBotButton({
  style,
  handleClickOpenDialog,
  isTariffFree,
}: {
  style?: CSSProperties;
  isVisible?: boolean;
  isAdmin?: boolean;
  isTariffFree?: boolean;
  isClientRequestVisible?: boolean;
  handleClickOpenDialog: (value: HeaderDialogsEnum) => void;
}) {
  const dispatch = useDispatch();

  const [url, setUrl] = useState<string>();

  const getTelegramUrl = async () => {
    const { ok, data, status, message } = await usersProvider.botConnectToken();
    if (ok && data) {
      setUrl(data);
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
    }
  };

  useEffect(() => {
    getTelegramUrl();
  }, [])
  

  const telegramConnect = () => {
    if (url) {
      window.open(`${url}`, '_blank');
    }
  };

  return (
    <StyledButtonOutlined
      style={{ width: '100%', ...style }}
      type='submit'
      variant='outlined'
      onClick={() =>
        isTariffFree
          ? handleClickOpenDialog(HeaderDialogsEnum.Tariff)
          : telegramConnect()
      }
    >
      <img src={AddTelegram} alt='Add Telegram bot' />
      Add Telegram bot
    </StyledButtonOutlined>
  );
}
