import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { State } from '@/types';
import { Dashboard, Ad, NewLogIn } from '@/components';

export const AdPage = () => {
  const { isLoggedIn } = useSelector(({ auth }: State) => auth);
  const { pathname } = useLocation();

  return (
    <Dashboard>
      {isLoggedIn ? <Ad /> : <NewLogIn route={pathname} />}
    </Dashboard>
  );
};
