import { useEffect } from 'react';
import { ThemeProvider, useTheme } from '@mui/material/styles';

import { sessionProvider } from '@/providers';
import { customTheme } from '@/utils';
import { Router } from '@/router';
// import { NotificationsSnackbar } from '@/components';

import './App.css';
import 'photoswipe/style.css';

import { CssBaseline } from '@mui/material';

export const App = () => {
  const outerTheme = useTheme();

  useEffect(() => {
    sessionProvider.initRefreshToken();
  }, []);

  return (
    <ThemeProvider theme={customTheme(outerTheme)}>
      <CssBaseline />
      <Router />
      {/* <NotificationsSnackbar /> */}
    </ThemeProvider>
  );
};
