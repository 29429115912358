import {
  FileKindEnum,
  IPdfDocument,
  ActivityKindEnum,
  RentPeriodEnum,
  TransactionTypeEnum,
  CompletionStatusEnum,
  AdFieldRenovationEnum,
} from '@/types';
import ReactPDF, { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { Section } from './Section';
import { Badge, IconText } from './Badge';
import { FeaturesSection } from './FeaturesSection';

import {
  BedIcon,
  BuildingsIcon,
  FloorIcon,
  FurnishingIcon,
  HouseLineIcon,
  LocationIcon,
  PaintRollerIcon,
  RulerIcon,
  ShowerIcon,
  DeveloperIcon,
  SellerIcon,
  PostHandoverIcon,
  HandoverIcon,
} from '@/assets/pdf';

import { AddressIconText } from './AddressIconText';
import { ContactSection } from './ContactSection';
import { GallerySection } from './GallerySection';
import { TopGalleryADS } from './TopGalleryADS';
import { Price } from './Price';

ReactPDF.Font.register({
  family: 'Poppins',
  src: '/assets/fonts/Poppins-Regular.ttf',
});
ReactPDF.Font.register({
  family: 'Poppins',
  fontWeight: 'bold',
  src: '/assets/fonts/Poppins-Bold.ttf',
});

const styles = StyleSheet.create({
  page: {
    color: '#2A3842',
    fontFamily: 'Poppins',
    fontSize: 40,
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },
  pageDescription: {
    fontSize: 40,
  },
});

export function PdfDocument({
  showContent,
  adsData,
  images,
  allImages,
  personalInfo,
  currentAgencyInfo,
  isNumberOfFloorsShown,
}: IPdfDocument) {
  const {
    title,
    nameEn,
    addressEn,
    paymentPlan,
    defaultRentFrequency,
    activityKind,
    convertedPrice,
    convertedPriceYearly,
    convertedPriceMonthly,
    convertedPriceWeekly,
    convertedPriceDaily,
    convertedCurrency,
    floorsNum,
    floor,
    propertyAdMedia,
    propertyKind,
    completionStatus,
    convertedArea,
    plotArea,
    convertedAreaUnits,
    furniture,
    baths,
    beds,
    renovation,
    description,
    freeHold,
    viewOfCity,
    viewOfLandmark,
    viewOfPark,
    viewOfWater,
    toSeller,
    toDeveloper,
    handover,
    postHandover,
    transactionType,
    view,
    convertedSellingPrice,
  } = adsData;
  const plans = propertyAdMedia.filter((item) => item.kind === FileKindEnum.Plan);
  const isSale = activityKind !== ActivityKindEnum.Rent;
  const textBeds = beds === 'Studio' ? '' : Number(beds) > 1 ? 'beds' : 'bed';
  const textBaths = Number(baths) > 1 ? 'baths' : 'bath';

  const getFloorText = () => {
    if (floor) {
      return `${capitalizeFirstLetter(floor)} floor`;
    }
    return null;
  };

  const renovationLabels = {
    [AdFieldRenovationEnum.Renovation]: 'Renovated',
    [AdFieldRenovationEnum.NonRenovation]: 'Non renovated',
  };

  const isSellingBlockVisible =
    transactionType === TransactionTypeEnum.Resale ||
    (transactionType === TransactionTypeEnum.Distress &&
      completionStatus === CompletionStatusEnum.OffPlan);

  const floorText = getFloorText();
  return (
    <Document>
      <Page size='A4' dpi={300} style={styles.page} orientation='landscape'>
        <ContactSection
          showBroker={showContent.isBrokersInformationVisible}
          showAgency={showContent.isAgencyInformationVisible}
          contactInfo={personalInfo}
        />
        <View style={{ display: 'flex', flexDirection: 'column', gap: 40, padding: '40px' }}>
          <View>
          <TopGalleryADS images={images} contactInfo={personalInfo} />
            <View
              style={{ flexDirection: 'row', gap: 16, position: 'absolute', left: 40, top: 40 }}
            >
              {transactionType && (
                <Badge iconSrc={HouseLineIcon}>{capitalizeFirstLetter(transactionType)}</Badge>
              )}
              {propertyKind && (
                <Badge iconSrc={BuildingsIcon}>{capitalizeFirstLetter(propertyKind)}</Badge>
              )}
            </View>
            <View
              style={{ flexDirection: 'row', gap: 16, position: 'absolute', left: 40, bottom: 56 }}
            >
              {view &&
                view?.length > 0 &&
                view.map((elementView) => {
                  return (
                    <Badge
                      style={{
                        backgroundColor: '#fff',
                        color: '#2A3842',
                        borderRadius: '50%',
                        fontSize: '30px',
                      }}
                    >
                      {`${elementView} view`}
                    </Badge>
                  );
                })}
            </View>
          </View>
          <View style={{ gap: 40 }}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '20px',
                justifyContent: 'space-between',
              }}
            >
              <AddressIconText iconSrc={LocationIcon}>{addressEn}</AddressIconText>
              {showContent.showPrice ? (
                <Price
                  isSale={isSale}
                  convertedPrice={convertedPrice}
                  convertedPriceYearly={convertedPriceYearly}
                  convertedPriceMonthly={convertedPriceMonthly}
                  convertedPriceWeekly={convertedPriceWeekly}
                  convertedPriceDaily={convertedPriceDaily}
                  convertedCurrency={convertedCurrency}
                  convertedSellingPrice={convertedSellingPrice}
                  transactionType={transactionType}
                />
              ) : null}
            </View>
            <View style={{ flexDirection: 'row', gap: 32, alignItems: 'center' }}>
              <IconText iconSrc={RulerIcon}>
                {`${plotArea ? `${plotArea} / ` : ''}${convertedArea ?? ''} ${convertedAreaUnits?.toLowerCase() ?? ''}`}
              </IconText>
              {beds && <Separator />}
              {beds && <IconText iconSrc={BedIcon}>{`${beds} ${textBeds}`}</IconText>}
              {furniture && <Separator />}
              {furniture && (
                <IconText iconSrc={FurnishingIcon}>{capitalizeFirstLetter(furniture)}</IconText>
              )}
              {baths && <Separator />}
              {baths && <IconText iconSrc={ShowerIcon}>{`${baths} ${textBaths}`}</IconText>}
              {floorText && <Separator />}
              {floorText && <IconText iconSrc={FloorIcon}>{floorText}</IconText>}
              {renovation && <Separator />}
              {renovation && <IconText iconSrc={PaintRollerIcon}>{renovationLabels[renovation]}</IconText>}
            </View>
            {isSellingBlockVisible && (
              <View style={{ flexDirection: 'row', gap: 32, alignItems: 'center' }}>
                {toSeller && (
                  <IconText iconSrc={SellerIcon}>
                    To seller:{' '}
                    <Text style={{ color: '#1650FF', fontWeight: 500 }}>
                      {numberFormat.format(toSeller)} {convertedCurrency}
                    </Text>
                  </IconText>
                )}
                {toDeveloper && <Separator />}
                {toDeveloper && (
                  <IconText iconSrc={DeveloperIcon}>
                    To developer:{' '}
                    <Text style={{ color: '#1650FF', fontWeight: 500 }}>
                      {numberFormat.format(toDeveloper)} {convertedCurrency}
                    </Text>
                  </IconText>
                )}
                {postHandover && <Separator />}
                {postHandover && (
                  <IconText iconSrc={PostHandoverIcon}>
                    Post handover: <Text style={{ color: '#1650FF', fontWeight: 500 }}>Yes</Text>
                  </IconText>
                )}
                {handover && <Separator />}
                {handover && (
                  <IconText iconSrc={HandoverIcon}>
                    Handover:{' '}
                    <Text style={{ color: '#1650FF', fontWeight: 500 }}>
                      {handover.quarter} / {handover.year}
                    </Text>
                  </IconText>
                )}
              </View>
            )}
            <FeaturesSection data={adsData} />
          </View>
        </View>
      </Page>
      {description && (
        <Page size='A4' dpi={300} style={styles.page} orientation='landscape'>
          <ContactSection
            showBroker={showContent.isBrokersInformationVisible}
            showAgency={showContent.isAgencyInformationVisible}
            contactInfo={personalInfo}
          />
          <View style={{ display: 'flex', flexDirection: 'column', gap: 40, padding: '40px' }}>
            <Section title={'Description'} wrap={false}>
              <Text style={styles.pageDescription}>{description}</Text>
            </Section>
          </View>
        </Page>
      )}
      <Page size='A4' dpi={300} style={styles.page} orientation='landscape'>
        <View style={{ display: 'flex', flexDirection: 'column', gap: 40, padding: '40px' }}>
          <GallerySection wrap={true} title='Gallery' images={allImages} />
        </View>
      </Page>
    </Document>
  );
}

const Separator = () => (
  <View
    style={{
      height: '56px',
      width: 4,
      backgroundColor: '#2A3842',
      marginHorizontal: 0,
    }}
  />
);

const capitalizeFirstLetter = (string?: string) => {
  if (!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const numberFormat = new Intl.NumberFormat('en', {
  maximumSignificantDigits: 3,
});
