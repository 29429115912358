import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Control } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
  Menu,
  MenuItem,
} from '@mui/material';
import {
  MyTextField,
  MyButton,
  Loader,
  MyPhoneInput,
  MyLink,
  MySelect,
  MyAutocomplete,
  MyDialog,
} from '@/components';
import {
  logInData,
  signUp,
  resetPasswordData,
  createPasswordDataLogIn,
} from '../NewSignUp/Utils/signUpDescription';
import { schemaLogIn } from '../NewSignUp/Utils/schemas';
import { LogInContentRenderer } from './LogInContentRenderer';
import {
  sectionStyle,
  divStyleLogIn,
  stackStyle,
  divStyle,
  stackStyleLogIn,
  LogInStyle,
  typographyH5Style,
  typographyBody2Style,
  boxStyle,
  boxInnerStyle,
  boxInnerStyleBottom,
  supportButtonStyle,
  typographyH4StyleLogIn,
  typographyBody1Style,
  typographyH4Style,
} from '../NewSignUp/Utils/styled';
import { ILogIn, ILogInData } from '../NewSignUp/Utils/types';
import { IDialogContent } from '../utils/types';
import { authLogin, authTariff, appSetNotification, appSetLoading } from '@/store';
import { authProvider, usersProvider } from '@/providers';
import {
  NotificationTypeEnum,
  UserModel,
  State,
  AuthResetPasswordRequestType,
  UserKindEnum,
  UserTariffPlanEnum,
} from '@/types';
import { StyledLink, StyleLabel } from '@/components/uiComponents/styled';
import { LeftSide, Header, CreatePassword, EmailConfirmation } from '../NewSignUp/';
import { schemaCreatePassword } from '../NewSignUp/Utils/schemas';

export const useLogInForm = (logInContent: IDialogContent[]) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    defaultValues: {
      emailOrPhone: '',
      password: '',
      phoneNumber: '',
    },
  });
  return {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
  };
};

export const NewLogIn = ({route}: {route?: string}) => {
  const { code: inviteLinkCode } = useParams();
  const navigate = useNavigate();
  const [inviteCode, setInviteCode] = useState<string | null>(localStorage.getItem('inviteCode'));
  const [unsubscribe, setUnsubscribe] = useState<string | null>(
    localStorage.getItem('unsubscribe')
  );
  const [pathName, setPathName] = useState<string | null>(localStorage.getItem('pathName'));
  const [emailOrPhoneInput, setEmailOrPhoneInput] = useState<string>('');
  const [logInPhoneValue, setLogInPhoneValue] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [status, setStatus] = useState<number>();
  const [stepValue, setStepValue] = useState<number>(0);
  const [dialogDatas, setDialogDatas] = useState({
    dialogData: logInData,
    dialogResetData: resetPasswordData,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userDataSubmit, setUserDataSubmit] = useState<AuthResetPasswordRequestType>();
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [password, setPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();
  const [helperText, setHelperText] = useState<string>('');

  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      if (status === 401 || status === 404) {
        setDialogDatas((prevState) => ({
          ...prevState,
          dialogData: updateErrorVisibility(logInData, 'error', true),
          dialogResetData: updateErrorVisibility(resetPasswordData, 'error', true),
        }));
      }
      if (status === 400) {
        setDialogDatas((prevState) => ({
          ...prevState,
          dialogData: updateErrorVisibility(logInData, 'error400', true),
          dialogResetData: updateErrorVisibility(resetPasswordData, 'error400', true),
        }));
      }
    } else {
      setDialogDatas((prevState) => ({
        ...prevState,
        dialogData: resetErrorVisibility(logInData, 'error400', 'error'),
        dialogResetData: resetErrorVisibility(resetPasswordData, 'error400', 'error'),
      }));
    }
  }, [error]);

  const updateErrorVisibility = (data: ILogInData, name: string, isVisible: boolean) => {
    const updatedDialogContent = data.logInContent.map((item: IDialogContent) => {
      if (item.name === name) {
        return {
          ...item,
          initialVisibility: isVisible,
        };
      }
      return item;
    });

    return {
      ...data,
      logInContent: updatedDialogContent,
    };
  };

  const resetErrorVisibility = (data: ILogInData, name: string, nameErr: string) => {
    const updatedDialogContent = data.logInContent.map((item: IDialogContent) => {
      if (item.name === name || item.name === nameErr) {
        return {
          ...item,
          initialVisibility: false,
        };
      }
      return item;
    });

    return {
      ...data,
      logInContent: updatedDialogContent,
    };
  };

  useEffect(() => {
    setEmailOrPhoneInput(logInPhoneValue);
    setValue('emailOrPhone', logInPhoneValue);
  }, [logInPhoneValue]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { handleSubmit, errors, control, setValue } = useLogInForm(logInData.logInContent);

  const onSubmitLogIn = async (submitData: any) => {
    const { emailOrPhone, password, phoneNumber } = submitData;
    const login = emailOrPhoneInput ? emailOrPhone.replace(/\s/g, '') : phoneNumber;
    setIsLoading(true);
    const { ok, data, status, message } = await authProvider.login(login, password);
    const result = data as UserModel;
    if (ok && data) {
      const isViewTariffPlan =
        result.tariffPlan?.name === UserTariffPlanEnum.Free ||
        result.tariffPlan?.name === UserTariffPlanEnum.Trial;
      const userKind = result?.kind;
      if (isViewTariffPlan) {
        const {
          ok: okTariff,
          data: dataTariff,
          status: statusTariff,
          message: messageTariff,
        } = await usersProvider.getTariffPlan();
        if (!okTariff) {
          dispatch(appSetNotification(NotificationTypeEnum.Error, messageTariff, statusTariff));
        } else {
          const { list } = dataTariff;
          const tariff = list.filter((el: any) =>
            el.userKinds.split(',').find((kind: UserKindEnum) => kind === userKind)
          );
          const prices = tariff.map((el: any) => {
            return {name: el.name, description: el.stripeProduct.description, prices: el.stripeProduct.prices};
          });
          dispatch(authTariff(prices));
        }
      }
      dispatch(authLogin(result));
      setEmailOrPhoneInput('');
      setLogInPhoneValue('');
      if (inviteCode) {
        navigate(`/invitelink/${inviteCode}`);
      } else if (unsubscribe) {
        navigate(`${pathName}`);
      } else {
        navigate(route ? `${route}` : '/');
      }
    } else {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
      setError(true);
      setStatus(status);
    }
    setIsLoading(false);
  };

  const onSubmitResetPass = async () => {
    const login = emailOrPhoneInput !== null ? emailOrPhoneInput : logInPhoneValue;
    const dataSubmit: AuthResetPasswordRequestType = { login: login || undefined };
    setIsLoading(true);
    const { ok, data, status, message } = await authProvider.postRecoverPasswordRequest(dataSubmit);
    if (ok) {
      setStepValue(stepValue + 1);
      setUserDataSubmit(dataSubmit);
      setError(false);
    } else {
      status !== 406
        ? dispatch(appSetNotification(NotificationTypeEnum.Error, message, status))
        : setOpenError(true);
      setError(true);
      setStatus(status);
    }
    setIsLoading(false);
  };

  const toggleVisibility = (state: any, name: string, visibility: boolean) => {
    state.dialogData.logInContent.find((item: any) => item.name === name).initialVisibility =
      visibility;
    state.dialogResetData.logInContent.find((item: any) => item.name === name).initialVisibility =
      visibility;
  };

  const handleInputChange = (value: string, name: string) => {
    let newValue = value.replace(/\s/g, '');
    if (newValue.startsWith('+')) {
      newValue = '+' + newValue.slice(1).replace(/[^\d]/g, '');
    }
    const isNumberOrPlus = /^(\+?\d*)$/.test(newValue);
    setEmailOrPhoneInput(newValue);
    setDialogDatas((prevState) => {
      const newState = { ...prevState };
      if (newValue === '' || !isNumberOrPlus) {
        toggleVisibility(newState, 'emailOrPhone', true);
        toggleVisibility(newState, 'phoneNumber', false);
      } else {
        toggleVisibility(newState, 'emailOrPhone', false);
        toggleVisibility(newState, 'phoneNumber', true);
      }
      return newState;
    });

    if (newValue === '' || !isNumberOrPlus) {
      setValue('emailOrPhone', newValue);
      setValue('phoneNumber', newValue);
    }
  };

  const handleInputClick = (e: any) => {
    setError(false);
    setStatus(status);
    if (
      dialogDatas.dialogData.logInContent.some(
        (item) => item.name === 'error' && item.initialVisibility === true
      ) ||
      dialogDatas.dialogResetData.logInContent.some(
        (item) => item.name === 'error' && item.initialVisibility === true
      )
    ) {
      setDialogDatas((prevState) => ({
        ...prevState,
        dialogData: logInData,
        dialogResetData: resetPasswordData,
      }));
    }
  };

  const onErrorClose = () => {
    setOpenError(false);
    setStepValue(stepValue - 1);
    setError(false);
  };

  const handlerNext = async () => {
    if (stepValue === 1) {
      onSubmitResetPass();
    }
    if (stepValue === 3) {
      try {
        await schemaCreatePassword.validate({
          password,
          confirmPassword,
        });
        if (password && userDataSubmit) {
          const dataSubmit = { ...userDataSubmit, password: password };
          setIsLoading(true);
          const response = await authProvider.postRecoverPasswordChange(dataSubmit);
          const { ok, message, status } = response;
          if (ok) {
            setOpenSuccess(true);
            setStepValue(stepValue - 3);
          } else {
            dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
          }
          setIsLoading(false);
        }
      } catch (err) {
        if (err instanceof yup.ValidationError) {
          setHelperText(err.errors[0]);
          setError(true);
        }
      }
    }
  };

  const handlePasswordChange = (value: string, name: string) => {
    if (name === 'password') {
      setPassword(value);
    } else {
      setConfirmPassword(value);
    }
  };

  const headerName =
    stepValue === 0
      ? 'Authorization'
      : stepValue === 1
        ? 'Reset password'
        : stepValue === 2
          ? 'Data confirmation'
          : 'Create password';
  const headerNext =
    stepValue === 0 || stepValue === 3
      ? 'Next: Start use system'
      : stepValue === 1
        ? 'Next: Data confirmation'
        : 'Next: Create password';
  const step = stepValue === 0 || stepValue === 1 ? 1 : stepValue === 2 ? 2 : 3;

  return (
    <>
      {isLoading && <Loader />}
      <Stack sx={sectionStyle}>
        <Stack direction={{ xs: 'column', sm: 'row' }} style={divStyle}>
          {!isMobile && <LeftSide type='logIn' />}
          <Stack spacing={2} alignItems='center'>
            <Stack sx={stackStyle}>
              <Header
                stepValue={step}
                type='logIn'
                headerName={headerName}
                headerNext={headerNext}
                MAX_STEP={stepValue === 0 ? 1 : 3}
              />
              <Divider sx={{ border: `1px solid #E9EEF1`, width: '100%' }} />
              {stepValue === 0 && (
                <Stack sx={LogInStyle}>
                  <Typography variant='h4' sx={typographyH4Style}>
                    {logInData.logInTitle.label}
                  </Typography>
                  <LogInContentRenderer
                    control={control}
                    onSubmit={handleSubmit(onSubmitLogIn)}
                    dialogData={dialogDatas.dialogData}
                    errors={errors}
                    onChange={handleInputChange}
                    onClick={handleInputClick}
                    emailOrPhoneInput={emailOrPhoneInput}
                    setLogInPhoneValue={setLogInPhoneValue}
                    setValue={setValue}
                    stepValue={stepValue}
                    setStepValue={setStepValue}
                    setError={setError}
                  />
                </Stack>
              )}

              {stepValue === 1 && (
                <Stack sx={LogInStyle}>
                  <Typography variant='h4' sx={typographyH4Style}>
                    {resetPasswordData.logInTitle.label}
                  </Typography>
                  <Typography variant='body1' sx={typographyBody1Style}>
                    {resetPasswordData.logInTitle.description}
                  </Typography>
                  <LogInContentRenderer
                    control={control}
                    onSubmit={handleSubmit(onSubmitLogIn)}
                    dialogData={dialogDatas.dialogResetData}
                    errors={errors}
                    onChange={handleInputChange}
                    onClick={handleInputClick}
                    emailOrPhoneInput={emailOrPhoneInput}
                    setLogInPhoneValue={setLogInPhoneValue}
                    setValue={setValue}
                    stepValue={stepValue}
                    setStepValue={setStepValue}
                    setError={setError}
                  />
                </Stack>
              )}
              {stepValue === 2 && (
                <Stack sx={LogInStyle}>
                  <EmailConfirmation
                    error={error}
                    setError={setError}
                    loginDataSubmit={userDataSubmit}
                    type='logIn'
                    setLoginDataSubmit={setUserDataSubmit}
                    stepValue={stepValue}
                    setStepValue={setStepValue}
                  />
                </Stack>
              )}
              {stepValue === 3 && (
                <Stack sx={LogInStyle}>
                  <CreatePassword
                    onChange={handlePasswordChange}
                    error={error}
                    helperText={helperText}
                    setError={setError}
                    loginDataSubmit={userDataSubmit}
                    type='logIn'
                  />
                </Stack>
              )}
              {stepValue !== 0 && (
                <Stack
                  spacing={{ sm: 3, xs: 1 }}
                  direction='row'
                  sx={{
                    display: 'flex',
                    justifyContent: { sm: 'flex-start', xs: 'space-evenly' },
                    padding: { sm: '10px 30px 20px', xs: '10px 2px' },
                    width: { sm: '504px', xs: '100%' },
                  }}
                >
                  <Box sx={boxInnerStyleBottom}>
                    <MyButton
                      data={{
                        buttonName: 'Back',
                        variant: 'outlined',
                        styleType: 'cancel',
                        dataQa: 'sign_up_btn_back',
                        customWidth: '170px',
                      }}
                      onClick={() => {
                        setError(false);
                        setStepValue(stepValue - 1);
                      }}
                    />
                  </Box>
                  {stepValue !== 2 && (
                    <Box sx={boxInnerStyleBottom}>
                      <MyButton
                        data={{
                          buttonName: 'Next',
                          variant: 'contained',
                          buttonType: 'submit',
                          dataQa: 'sign_up_btn_next',
                          customWidth: '170px',
                        }}
                        onClick={() => handlerNext()}
                      />
                    </Box>
                  )}
                </Stack>
              )}
            </Stack>
            {isMobile && (
              <Stack direction='row' alignItems='center' display='flex' sx={{ maxWidth: '100%' }}>
                <Typography
                  sx={{
                    color: '#2A3842',
                    fontSize: '15px',
                    textAlign: isMobile ? 'center' : 'left',
                  }}
                >
                  {signUp.passiveLabel}{' '}
                  <StyledLink to='/signUp' fontSize='15px' fontWeight='800'>
                    {signUp.label}
                  </StyledLink>{' '}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
      <MyDialog open={openError} onClose={onErrorClose} dialogTitle='Error' width='500'>
        <StyleLabel style={{ textAlign: 'center', color: 'red' }}>
          You will be able to request a new code in 2 minutes
        </StyleLabel>
      </MyDialog>
      <MyDialog
        open={openSuccess}
        onClose={() => setOpenSuccess(false)}
        dialogTitle='Success'
        width='500'
      >
        <Stack spacing={2} sx={{ width: '100%' }}>
          <StyleLabel style={{ textAlign: 'center' }}>Your password was changed</StyleLabel>
          <MyButton
            data={{
              buttonName: 'Close',
              variant: 'contained',
              customWidth: '358px',
              styleType: 'dialog',
            }}
            onClick={() => setOpenSuccess(false)}
          />
        </Stack>
      </MyDialog>
    </>
  );
};
