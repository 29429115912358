import { AdMediaType } from '@/types';
import React, { useMemo } from 'react';
import { Picture } from '@/components/PdfManager/PdfDocument/ClassicView/Picture';
import { Title } from '@/components/PdfManager/PdfDocument/ClassicView/Section';
import { StyleSheet, View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { NewBuildingsMediaType } from '@/types/newBuildings';

export interface GallerySectionProps {
  title?: string;
  images?: AdMediaType[] | NewBuildingsMediaType[] | string[];
  wrap?: boolean;
  style?: Style;
}

const styles = StyleSheet.create({
  gallery: {
    width: '100%',
  },
  galleryItem: {
    flex: 2,
    flexDirection: 'row',
    flexWrap: 'wrap',
    columnGap: 32,
  },
  galleryImage: {
    marginTop: 32,
    width: '49%',
    objectFit: 'cover',
    borderRadius: 8,
    height: '718px',
  },
});

export const GallerySection = ({
  title,
  images = [],
  wrap = false,
  style,
}: GallerySectionProps) => {
  if (!images.length) return null;

  const pictures = useMemo(() => {
    return images.map((img) => {
      if (typeof img === 'string') {
        return <Picture key={img} style={styles.galleryImage} src={img} />;
      }
  
      return (
        <Picture key={img.id} style={styles.galleryImage} src={img.fileUrl} />
      );
    });
  }, [images]);
  

  return (
    <View wrap={wrap}>
      <Title>{title}</Title>
      <View style={styles.gallery} wrap={true}>
        <View style={styles.galleryItem} wrap={true}>
          {pictures}
        </View>
      </View>
    </View>
  );
};
