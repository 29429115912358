import { AdModel, UserModel } from '@/types';
import { SelectedDataType } from '../AdsPdfManager';
import { ClassicView } from './ClassicView';
import { VerticalView } from './VerticalView';
import { HorizontalView } from './HorizontalView';
import { AllInOneView } from './AllInOneView';
import { LuxView } from './LuxView';
import { OnePageView } from './OnePageView';
import { PortableView } from './PortableView';

export function GeneratePDF({
  showContent,
  adsData,
  images,
  allImages,
  personalInfo,
  currentAgencyInfo,
  isNumberOfFloorsShown,
  templateName,
}: {
  showContent: SelectedDataType;
  adsData: AdModel;
  images: string[];
  allImages: string[];
  personalInfo: UserModel;
  currentAgencyInfo?: UserModel;
  isNumberOfFloorsShown?: boolean;
  templateName: string;
}) {
  const ViewComponents = {
    'horizontal-view': HorizontalView,
    'vertical-view': VerticalView,
  };

  const SelectedView = ViewComponents[templateName as keyof typeof ViewComponents];

  return SelectedView ? (
    <SelectedView
      adsData={adsData}
      images={images}
      allImages={allImages}
      personalInfo={personalInfo}
      currentAgencyInfo={currentAgencyInfo}
      showContent={showContent}
      isNumberOfFloorsShown={isNumberOfFloorsShown}
    />
  ) : null;
}
