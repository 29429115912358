import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationTypeEnum, State, UserModel, UserKindEnum, UserPermissions, UserTariffPlanEnum } from '@/types';
import { usersProvider } from '@/providers';
import { appSetNotification, authGetMyUser, authSetIsLoggedIn, authTariff } from '@/store';
import { AUTH_ACCESSTOKEN } from '@/constants';
import { userDefaultValues } from '@/store/reducers/authReducer';

export function useUserProfile(onUnauthorized?: () => void) {
  const { isLoggedIn } = useSelector(({ auth }: State) => auth);
  const [userData, setUserData] = useState<UserModel>({
    ...userDefaultValues,
  });
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const refetchUserProfile = useCallback(async () => {
    setIsLoading(true);
    const { ok, data } = await usersProvider.getMyUser();
    if (ok && data) {
      const result = data as UserModel;
      const isViewTariffPlan =
        result.tariffPlan?.name === UserTariffPlanEnum.Free ||
        result.tariffPlan?.name === UserTariffPlanEnum.Trial;
      const userKind = result?.kind;
      if (isViewTariffPlan) {
        const {
          ok: okTariff,
          data: dataTariff,
          status: statusTariff,
          message: messageTariff,
        } = await usersProvider.getTariffPlan();
        if (!okTariff) {
          dispatch(appSetNotification(NotificationTypeEnum.Error, messageTariff, statusTariff));
        } else {
          const { list } = dataTariff;
          const tariff = list.filter((el: any) =>
            el.userKinds.split(',').find((kind: UserKindEnum) => kind === userKind)
          );
          const prices = tariff.map((el: any) => {
            return {name: el.name, description: el.stripeProduct.description, prices: el.stripeProduct.prices};
          });
          dispatch(authTariff(prices));
        }
      }
      dispatch(authGetMyUser(data));
      setUserData(data);
    } else {
      dispatch(authSetIsLoggedIn(false));
      dispatch(
        appSetNotification(
          NotificationTypeEnum.Error,
          'Your access token is expired, please login again'
        )
      );
    }
    setIsLoading(false);
  }, [dispatch]);

  const invalidateUserProfile = useCallback(() => {
    setUserData({ ...userDefaultValues });
    // TODO: doesnt work wit global store because UserModel cannot be a null
    // need to refactor too a lot components or remove this method
    // dispatch(authGetMyUser(null));
    refetchUserProfile();
  }, [refetchUserProfile]);

  useEffect(() => {
    if (!isLoggedIn && !localStorage.getItem(AUTH_ACCESSTOKEN)) {
      dispatch(authSetIsLoggedIn(false));
      setIsLoading(false);
      onUnauthorized && onUnauthorized();
    } else if (!isLoggedIn && localStorage.getItem(AUTH_ACCESSTOKEN)) {
      refetchUserProfile();
    } else if (isLoggedIn) {
      setIsLoading(false);
    }
  }, [refetchUserProfile]);

  return {
    userData,
    isLoading,
    isLoggedIn,
    refetchUserProfile,
    invalidateUserProfile,
  };
}
